@import "./../../styles/shared.scss";

.tabs {
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
    padding-left: 0;
    margin-bottom: 2rem;
    border-bottom: 1px solid $color-tertiary-5;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    min-height: 40px;

    &-item {
      padding-bottom: .5rem;

      &-link {
        font-family: $font-primary;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 0.9375rem; /* 125% */
        letter-spacing: 0.0525rem;
        text-transform: uppercase;
        color: $color-secondary-2;
        text-decoration: none;
        position: relative;
        white-space: nowrap;

        &:after {
          content: "";
          position: absolute;
          bottom: -.95rem;
          left: 0;
          display: block;
          width: 100%;
          height: 0rem;
          background-color: $color-principal;
          transition: all .1s ease;
        }

        &:hover,
        &:focus {
          color: $color-principal;
        }

        &.active {
          color: $color-principal;
          &:after {
            bottom: -1.2rem;
            height: 0.125rem;
          }
        }
      }
    }
  }

  &__content {
    ul li { list-style: disc !important;}
    
    &-item {
      display: none;
      margin-bottom: 2rem;
      transition: all .3s ease;

      &.active {
        display: block;
      }
    }
  }
}
